import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import Airtable from 'airtable'
import Text from '../../common/Text'
import Space from '../../common/Space'
import { persistData, getPersistedData } from '../../../utils/localStorageHelpers'

// Airtable columns
const labelColName = 'Label'
const colName2020 = '2020'
const colName2021 = '2021'

// Non-GAAP columns

const threeMonth2021ColName = 'Three Months 2021'
const threeMonth2020ColName = 'Three Months 2020'

// configure Airtable
Airtable.configure({
  endpointUrl: 'https://api.airtable.com',
  apiKey: process.env.GATSBY_AIRTABLE_API_KEY,
})
const AIRTABLE_BASE = Airtable.base('appE7Q7Uzg1kidU1F')

const StyledTable = styled.table`
  width: 100%;
  font-family: ${p => p.theme.fonts.family.body};
  margin: 0 auto;

  caption {
    font-weight: 700;
    margin-bottom: 1.5em;
  }
  .text-weight-book {
    font-weight: 400 !important;
  }
  td,
  th {
    text-align: left;
    border-bottom: solid 1px gray;
    padding: 0.3em;
  }
  td.w-60,
  th.w-60 {
    width: 60%;
  }
  td.text-align-right,
  th.text-align-right {
    text-align: right;
  }
  td.text-align-center,
  th.text-align-center {
    text-align: center;
  }
  .text-header {
    font-weight: 400;
  }

  .large-header {
    font-size: 1.2em;
  }
  .indent {
    padding-left: 1em;
  }
  .mr-2 {
    margin-right: 1em;
  }
  .mt-2 {
    margin-top: 1em;
  }
  .mt-4 {
    margin-top: 2em;
  }
  .border-top {
    border-top: solid 1px black;
  }
  .no-border {
    border: none;
  }
  .subtotal,
  .total,
  .total2 {
    position: relative;
  }
  .subtotal:before {
    content: '';
    background: black;
    position: absolute;
    top: -4px;
    right: 0;
    height: 1px;
    width: 75%;
  }
  .subtotal.full-width:before {
    width: 100%;
  }
  .subtotal.space-between {
    display: flex;

    div {
      width: 50%;
      text-align: right;
      border-bottom: 1px solid black;

      &:not(:first-of-type) {
        margin-left: 12px;
      }
    }
  }
  .total:after {
    content: '';
    background: black;
    position: absolute;
    bottom: -4px;
    right: 0;
    height: 1px;
    width: 75%;
  }
  .total2:after {
    content: '';
    background: black;
    position: absolute;
    bottom: -7px;
    right: 0;
    height: 1px;
    width: 75%;
  }
  .spacer {
    padding: 0.8em;
  }
  .spacer-row-subtotal {
    .subtotal {
      height: 19px;
    }
  }
  .no-wrap {
    white-space: nowrap;
  }
`

const formatCurrency = currency =>
  currency ? currency.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') : <span>&mdash;</span>

const IsTable = ({ data }) => {
  const isData = data

  return (
    <StyledTable
      summary="Column one has the revenue source, other columns show the amounts by date period"
      id="ae_revenue"
      className="financial-table"
      border="0"
      cellpadding="0"
      cellspacing="0"
    >
      <thead>
        <tr>
          <td className="no-border" colSpan="2" />
          <th scope="col" className="text-align-center no-wrap" colSpan="2">
            Year ended December 31,
          </th>
        </tr>
        <tr>
          <td className="no-border" colSpan="2" />
          <th id="2021" scope="col" style={{ textAlign: 'center' }} className="no-wrap">
            2021
          </th>
          <th id="2020" scope="col" style={{ textAlign: 'center' }} className="no-wrap">
            2020
          </th>
        </tr>
        <tr>
          <td colSpan="4" className="text-align-left no-wrap">
            ( In thousands, except per share data )
          </td>
        </tr>
      </thead>
      <tbody>
        {isData.Revenue && (
          <tr>
            <th id="revenue" className="text-header" colSpan="2">
              Revenue
            </th>
            <td headers="2021 revenue" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(isData.Revenue[colName2021])}
            </td>
            <td headers="2020 revenue" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(isData.Revenue[colName2020])}
            </td>
          </tr>
        )}

        {isData['Cost of revenue'] && (
          <tr>
            <th id="costofrevenue" className="text-header" colSpan="2">
              Cost of revenue
            </th>
            <td headers="2021 costofrevenue" className="text-align-right no-border">
              {formatCurrency(isData['Cost of revenue'][colName2021])}
            </td>
            <td headers="2020 costofrevenue" className="text-align-right no-border">
              {formatCurrency(isData['Cost of revenue'][colName2020])}
            </td>
          </tr>
        )}

        <tr className="spacer-row-subtotal">
          <td role="presentation" colSpan="2" />
          <td role="presentation">
            <div className="subtotal">&nbsp;</div>
          </td>
          <td role="presentation">
            <div className="subtotal">&nbsp;</div>
          </td>
        </tr>

        {isData['Gross profit'] && (
          <tr>
            <th id="grossprofit" className="text-header" colSpan="2">
              Gross profit
            </th>
            <td headers="2021 grossprofit" className="text-align-right">
              {formatCurrency(isData['Gross profit'][colName2021])}
            </td>
            <td headers="2020 grossprofit" className="text-align-right">
              {formatCurrency(isData['Gross profit'][colName2020])}
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer" />
        </tr>
        <tr>
          <th id="oe" colSpan="4" scope="colgroup" className="text-header">
            Operating expenses:
          </th>
        </tr>

        {isData['Selling and marketing'] && (
          <tr>
            <th id="sam" className="text-header" colSpan="2">
              Selling and marketing
            </th>
            <td headers="2021 oe sam" className="text-align-right">
              {formatCurrency(isData['Selling and marketing'][colName2021])}
            </td>
            <td headers="2020 oe sam" className="text-align-right">
              {formatCurrency(isData['Selling and marketing'][colName2020])}
            </td>
          </tr>
        )}

        {isData['Research and development'] && (
          <tr>
            <th id="rad" className="text-header" colSpan="2">
              Research and development
            </th>
            <td headers="2021 oe rad" className="text-align-right">
              {formatCurrency(isData['Research and development'][colName2021])}
            </td>
            <td headers="2020 oe rad" className="text-align-right">
              {formatCurrency(isData['Research and development'][colName2020])}
            </td>
          </tr>
        )}

        {isData['General and administrative'] && (
          <tr>
            <th id="ga" className="text-header" colSpan="2">
              General and administrative
            </th>
            <td headers="2021 oe ga" className="text-align-right no-border">
              {formatCurrency(isData['General and administrative'][colName2021])}
            </td>
            <td headers="2020 oe ga" className="text-align-right no-border">
              {formatCurrency(isData['General and administrative'][colName2020])}
            </td>
          </tr>
        )}

        {isData['Total operating expenses'] && (
          <tr>
            <th id="toe" className="text-header" colSpan="2">
              Total operating expenses
            </th>
            <td headers="2021 oe toe" className="text-align-right">
              <div className="subtotal">{formatCurrency(isData['Total operating expenses'][colName2021])}</div>
            </td>
            <td headers="2020 oe toe" className="text-align-right">
              <div className="subtotal">{formatCurrency(isData['Total operating expenses'][colName2020])}</div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer" />
        </tr>

        {isData['Operating loss'] && (
          <tr>
            <th id="ol" className="text-header" colSpan="2">
              Operating loss
            </th>
            <td headers="2021 oe ol" className="text-align-right" aria-label="negative value">
              ({formatCurrency(isData['Operating loss'][colName2021])})
            </td>
            <td headers="2020 oe ol" className="text-align-right" aria-label="negative value">
              ({formatCurrency(isData['Operating loss'][colName2020])})
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer" />
        </tr>

        <tr>
          <th id="oie" colSpan="4" scope="colgroup" className="text-header">
            Other income (expense):
          </th>
        </tr>

        {isData['Gain on loan forgiveness'] && (
          <tr>
            <th id="ol" className="text-header" colSpan="2">
              Gain on loan forgiveness
            </th>
            <td headers="2021 lf oie" className="text-align-right">
              {formatCurrency(isData['Gain on loan forgiveness'][colName2021])}
            </td>
            <td headers="2020 lf oie" className="text-align-right">
              {formatCurrency(isData['Gain on loan forgiveness'][colName2020])}
            </td>
          </tr>
        )}

        {isData['Change in fair value of warrant liability'] && (
          <tr>
            <th id="wl" className="text-header" colSpan="2">
              Change in fair value of warrant liability
            </th>
            <td headers="2021 wl oie" className="text-align-right">
              {formatCurrency(isData['Change in fair value of warrant liability'][colName2021])}
            </td>
            <td headers="2020 wl oie" className="text-align-right">
              {formatCurrency(isData['Change in fair value of warrant liability'][colName2020])}
            </td>
          </tr>
        )}

        {isData['Interest expense'] && (
          <tr>
            <th id="iein" className="text-header" colSpan="2">
              Interest expense
            </th>
            <td headers="2021 iein oie" className="text-align-right no-border" aria-label="negative value">
              ({formatCurrency(isData['Interest expense'][colName2021])})
            </td>
            <td headers="2020 iein oie" className="text-align-right no-border" aria-label="negative value">
              ({formatCurrency(isData['Interest expense'][colName2020])})
            </td>
          </tr>
        )}

        {isData['Total other income (expense)'] && (
          <tr>
            <th id="toe" className="text-header" colSpan="2">
              Total other income (expense)
            </th>
            <td headers="2021 toe oie" className="text-align-right">
              <div className="subtotal">{formatCurrency(isData['Total other income (expense)'][colName2021])}</div>
            </td>
            <td headers="2020 toe oie" className="text-align-right" aria-label="negative value">
              <div className="subtotal">({formatCurrency(isData['Total other income (expense)'][colName2020])})</div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer" />
        </tr>

        {isData['Net loss'] && (
          <tr>
            <th id="nl" className="text-header" colSpan="2">
              Net loss
            </th>
            <td headers="2021 nl oie" className="text-align-right" aria-label="negative value">
              ({formatCurrency(isData['Net loss'][colName2021])})
            </td>
            <td headers="2020 nl oie" className="text-align-right" aria-label="negative value">
              ({formatCurrency(isData['Net loss'][colName2020])})
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer no-border" />
        </tr>

        {isData['Dividends on Series A Convertible Preferred Stock'] && (
          <tr>
            <th id="dosacps" className="text-header no-border" colSpan="2">
              Dividends on Series A Convertible Preferred Stock
            </th>
            <td headers="2021 dosacps oie" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                ({formatCurrency(isData['Dividends on Series A Convertible Preferred Stock'][colName2021])})
              </div>
            </td>
            <td headers="2020 dosacps oie" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                ({formatCurrency(isData['Dividends on Series A Convertible Preferred Stock'][colName2020])})
              </div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer no-border" />
        </tr>

        {isData['Net loss available to common stockholders'] && (
          <tr>
            <th id="nlatcs" className="text-header no-border" colSpan="2">
              Net loss available to common stockholders
            </th>
            <td headers="2021 nlatcs oie" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(isData['Net loss available to common stockholders'][colName2021])})
                </div>
              </div>
            </td>
            <td headers="2020 nlatcs oie" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(isData['Net loss available to common stockholders'][colName2020])})
                </div>
              </div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="5" role="presentation" className="spacer no-border" />
        </tr>

        {isData['Net loss per common share-basic and diluted'] && (
          <tr>
            <th id="nlpcsad" className="text-header no-border" colSpan="2">
              Net loss per common share-basic and diluted
            </th>
            <td headers="2021 nlpcsad oie" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(isData['Net loss per common share-basic and diluted'][colName2021])})
                </div>
              </div>
            </td>
            <td headers="2020 nlpcsad oie" className="text-align-right no-border" aria-label="negative value">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>(
                  {formatCurrency(isData['Net loss per common share-basic and diluted'][colName2020])})
                </div>
              </div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="4" role="presentation" className="spacer no-border" />
        </tr>

        {isData['Weighted average common shares outstanding-basic and diluted'] && (
          <tr>
            <th id="wacsoad" className="text-header no-border" colSpan="2">
              Weighted average common shares outstanding-basic and diluted
            </th>
            <td headers="2021 wacsoad oie" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  {formatCurrency(isData['Weighted average common shares outstanding-basic and diluted'][colName2021])}
                </div>
              </div>
            </td>
            <td headers="2020 wacsoad oie" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  {formatCurrency(isData['Weighted average common shares outstanding-basic and diluted'][colName2020])}
                </div>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </StyledTable>
  )
}

const BsTable = ({ data }) => {
  const bsData = data

  return (
    <StyledTable
      summary="Column one has the asset or liability, other columns show the amounts by date period"
      id="assets_liabilities"
      className="financial-table mt-4"
      border="0"
      cellpadding="0"
      cellspacing="0"
    >
      <thead>
        <tr>
          <td className="no-border" />
          <th scope="col" className="text-align-center no-border no-wrap" id="dec2021">
            December 31,
            <br /> 2021
          </th>
          <th scope="col" className="text-align-center no-border no-wrap" id="dec2020">
            December 31,
            <br /> 2020
          </th>
        </tr>

        <tr>
          <td role="presentation" className="no-border" />
          <td role="presentation" className="no-border">
            <div className="subtotal full-width" />
          </td>
          <td role="presentation" className="no-border">
            <div className="subtotal full-width" />
          </td>
        </tr>

        <tr>
          <td colSpan="5" className="text-align-left no-wrap">
            ( In thousands, except per share data )
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <th id="assets" colSpan="3" scope="colgroup" className="large-header">
            ASSETS
          </th>
        </tr>
        <tr>
          <th id="current_assets" colSpan="3" scope="colgroup" headers="assets" className="indent">
            Current assets:
          </th>
        </tr>

        {bsData.Cash && (
          <tr>
            <th headers="assets current_assets" id="cash" className="text-header indent">
              Cash
            </th>
            <td headers="assets current_assets cash dec2021" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(bsData.Cash[colName2021])}
            </td>
            <td headers="assets current_assets cash dec2020" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(bsData.Cash[colName2020])}
            </td>
          </tr>
        )}

        {bsData['Accounts receivable, net of allowance for doubtful accounts of $157 and $79, respectively'] && (
          <tr>
            <th headers="assets current_assets" id="arn" className="text-header indent">
              Accounts receivable, net of allowance for doubtful accounts of $157 and $79, respectively
            </th>
            <td headers="assets current_assets arn dec2021" className="text-align-right">
              {formatCurrency(
                bsData['Accounts receivable, net of allowance for doubtful accounts of $157 and $79, respectively'][
                  colName2021
                ]
              )}
            </td>
            <td headers="assets current_assets arn dec2020" className="text-align-right">
              {formatCurrency(
                bsData['Accounts receivable, net of allowance for doubtful accounts of $157 and $79, respectively'][
                  colName2020
                ]
              )}
            </td>
          </tr>
        )}

        {bsData['Unbilled receivables'] && (
          <tr>
            <th headers="assets current_assets" id="ur" className="text-header indent">
              Unbilled receivables
            </th>
            <td headers="assets current_assets ur dec2021" className="text-align-right">
              {formatCurrency(bsData['Unbilled receivables'][colName2021])}
            </td>
            <td headers="assets current_assets ur dec2020" className="text-align-right">
              {formatCurrency(bsData['Unbilled receivables'][colName2020])}
            </td>
          </tr>
        )}

        {bsData['Deferred costs, short term'] && (
          <tr>
            <th headers="assets current_assets" id="dcst" className="text-header indent">
              Deferred costs, short term
            </th>
            <td headers="assets current_assets dcst dec2021" className="text-align-right">
              {formatCurrency(bsData['Deferred costs, short term'][colName2021])}
            </td>
            <td headers="assets current_assets dcst dec2020" className="text-align-right">
              {formatCurrency(bsData['Deferred costs, short term'][colName2020])}
            </td>
          </tr>
        )}

        {bsData['Debt issuance costs, net'] && (
          <tr>
            <th headers="assets current_assets" id="dicn" className="text-header indent">
              Debt issuance costs, net
            </th>
            <td headers="assets current_assets dicn dec2021" className="text-align-right">
              {formatCurrency(bsData['Debt issuance costs, net'][colName2021])}
            </td>
            <td headers="assets current_assets dicn dec2020" className="text-align-right">
              {formatCurrency(bsData['Debt issuance costs, net'][colName2020])}
            </td>
          </tr>
        )}

        {bsData['Prepaid expenses and other current assets'] && (
          <tr>
            <th headers="assets current_assets" id="peaoca" className="text-header indent">
              Prepaid expenses and other current assets
            </th>
            <td headers="assets current_assets peaoca dec2021" className="text-align-right no-border">
              {formatCurrency(bsData['Prepaid expenses and other current assets'][colName2021])}
            </td>
            <td headers="assets current_assets peaoca dec2020" className="text-align-right no-border">
              {formatCurrency(bsData['Prepaid expenses and other current assets'][colName2020])}
            </td>
          </tr>
        )}

        {bsData['Total current assets'] && (
          <tr>
            <th headers="assets current_assets" id="tca" className="text-header indent">
              Total current assets
            </th>
            <td headers="assets current_assets tca dec2021" className="text-align-right">
              <div className="subtotal">{formatCurrency(bsData['Total current assets'][colName2021])}</div>
            </td>
            <td headers="assets current_assets tca dec2020" className="text-align-right">
              <div className="subtotal">{formatCurrency(bsData['Total current assets'][colName2020])}</div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="3" role="presentation" className="spacer" />
        </tr>

        {bsData['Property and equipment, net of accumulated depreciation of $210 and $209, respectively'] && (
          <tr>
            <th headers="assets current_assets" id="paen" className="text-header indent">
              Property and equipment, net of accumulated depreciation of $210 and $209, respectively
            </th>
            <td headers="assets current_assets paen dec2021" className="text-align-right">
              {formatCurrency(
                bsData['Property and equipment, net of accumulated depreciation of $210 and $209, respectively'][
                  colName2021
                ]
              )}
            </td>
            <td headers="assets current_assets paen dec2020" className="text-align-right">
              {formatCurrency(
                bsData['Property and equipment, net of accumulated depreciation of $210 and $209, respectively'][
                  colName2020
                ]
              )}
            </td>
          </tr>
        )}

        {bsData['Right of use assets'] && (
          <tr>
            <th headers="assets current_assets" id="roua" className="text-header indent">
              Right of use assets
            </th>
            <td headers="assets current_assets roua dec2021" className="text-align-right">
              {formatCurrency(bsData['Right of use assets'][colName2021])}
            </td>
            <td headers="assets current_assets roua dec2020" className="text-align-right">
              {formatCurrency(bsData['Right of use assets'][colName2020])}
            </td>
          </tr>
        )}

        {bsData['Deferred costs, long term'] && (
          <tr>
            <th headers="assets current_assets" id="dclt" className="text-header indent">
              Deferred costs, long term
            </th>
            <td headers="assets current_assets dclt dec2021" className="text-align-right">
              {formatCurrency(bsData['Deferred costs, long term'][colName2021])}
            </td>
            <td headers="assets current_assets dclt dec2020" className="text-align-right">
              {formatCurrency(bsData['Deferred costs, long term'][colName2020])}
            </td>
          </tr>
        )}

        {bsData['Intangible assets, net of accumulated amortization of $5,285 and $4,328, respectively'] && (
          <tr>
            <th headers="assets current_assets" id="ian" className="text-header indent">
              Intangible assets, net of accumulated amortization of $5,285 and $4,328, respectively
            </th>
            <td headers="assets current_assets ian dec2021" className="text-align-right">
              {formatCurrency(
                bsData['Intangible assets, net of accumulated amortization of $5,285 and $4,328, respectively'][
                  colName2021
                ]
              )}
            </td>
            <td headers="assets current_assets ian dec2020" className="text-align-right">
              {formatCurrency(
                bsData['Intangible assets, net of accumulated amortization of $5,285 and $4,328, respectively'][
                  colName2020
                ]
              )}
            </td>
          </tr>
        )}

        {bsData.Goodwill && (
          <tr>
            <th headers="assets current_assets" id="gw" className="text-header indent">
              Goodwill
            </th>
            <td headers="assets current_assets gw dec2021" className="text-align-right">
              {formatCurrency(bsData.Goodwill[colName2021])}
            </td>
            <td headers="assets current_assets gw dec2020" className="text-align-right">
              {formatCurrency(bsData.Goodwill[colName2020])}
            </td>
          </tr>
        )}

        {bsData.Other && (
          <tr>
            <th headers="assets current_assets" id="oth" className="text-header indent">
              Other
            </th>
            <td headers="assets current_assets oth dec2021" className="text-align-right no-border">
              {formatCurrency(bsData.Other[colName2021])}
            </td>
            <td headers="assets current_assets oth dec2020" className="text-align-right no-border">
              {formatCurrency(bsData.Other[colName2020])}
            </td>
          </tr>
        )}

        {bsData['Total assets'] && (
          <tr>
            <th headers="assets current_assets" id="ta" className="text-header indent">
              Total assets
            </th>
            <td headers="assets current_assets ta dec2021" className="text-align-right no-border">
              <div className="subtotal">
                <div className="total">
                  <div className="total2">
                    <span className="mr-2">$</span>
                    {formatCurrency(bsData['Total assets'][colName2021])}
                  </div>
                </div>
              </div>
            </td>
            <td headers="assets current_assets ta dec2020" className="text-align-right no-border">
              <div className="subtotal">
                <div className="total">
                  <div className="total2">
                    <span className="mr-2">$</span>
                    {formatCurrency(bsData['Total assets'][colName2020])}
                  </div>
                </div>
              </div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="3" role="presentation" className="spacer" />
        </tr>

        <tr>
          <th id="liabilities" colSpan="3" scope="colgroup" className="large-header">
            LIABILITIES AND STOCKHOLDERS' EQUITY
          </th>
        </tr>
        <tr>
          <th id="current_liabilities" colSpan="3" scope="colgroup" headers="liabilities" className="indent">
            Current liabilities:
          </th>
        </tr>

        {bsData['Accounts payable and accrued expenses'] && (
          <tr>
            <th headers="liabilities current_liabilities" id="apaae" className="text-header indent">
              Accounts payable and accrued expenses
            </th>
            <td headers="liabilities current_liabilities apaae dec2021" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(bsData['Accounts payable and accrued expenses'][colName2021])}
            </td>
            <td headers="liabilities current_liabilities apaae dec2020" className="text-align-right">
              <span className="mr-2">$</span>
              {formatCurrency(bsData['Accounts payable and accrued expenses'][colName2020])}
            </td>
          </tr>
        )}

        {bsData['Finance lease liabilities'] && (
          <tr>
            <th headers="liabilities current_liabilities" id="fll" className="text-header indent">
              Finance lease liabilities
            </th>
            <td headers="liabilities current_liabilities fll dec2021" className="text-align-right">
              {formatCurrency(bsData['Finance lease liabilities'][colName2021])}
            </td>
            <td headers="liabilities current_liabilities fll dec2020" className="text-align-right">
              {formatCurrency(bsData['Finance lease liabilities'][colName2020])}
            </td>
          </tr>
        )}

        {bsData['Operating lease liabilities'] && (
          <tr>
            <th headers="liabilities current_liabilities" id="oll" className="text-header indent">
              Operating lease liabilities
            </th>
            <td headers="liabilities current_liabilities oll dec2021" className="text-align-right">
              {formatCurrency(bsData['Operating lease liabilities'][colName2021])}
            </td>
            <td headers="liabilities current_liabilities oll dec2020" className="text-align-right">
              {formatCurrency(bsData['Operating lease liabilities'][colName2020])}
            </td>
          </tr>
        )}

        {bsData['Deferred revenue'] && (
          <tr>
            <th headers="liabilities current_liabilities" id="dr" className="text-header indent">
              Deferred revenue
            </th>
            <td headers="liabilities current_liabilities dr dec2021" className="text-align-right">
              {formatCurrency(bsData['Deferred revenue'][colName2021])}
            </td>
            <td headers="liabilities current_liabilities dr dec2020" className="text-align-right">
              {formatCurrency(bsData['Deferred revenue'][colName2020])}
            </td>
          </tr>
        )}

        {bsData['Contingent consideration'] && (
          <tr>
            <th headers="liabilities current_liabilities" id="cc" className="text-header indent">
              Contingent consideration
            </th>
            <td headers="liabilities current_liabilities cc dec2021" className="text-align-right">
              {formatCurrency(bsData['Contingent consideration'][colName2021])}
            </td>
            <td headers="liabilities current_liabilities cc dec2020" className="text-align-right">
              {formatCurrency(bsData['Contingent consideration'][colName2020])}
            </td>
          </tr>
        )}

        {bsData['Term loan, short term'] && (
          <tr>
            <th headers="liabilities current_liabilities" id="tlst" className="text-header indent">
              Term loan, short term
            </th>
            <td headers="liabilities current_liabilities tlst dec2021" className="text-align-right no-border">
              {formatCurrency(bsData['Term loan, short term'][colName2021])}
            </td>
            <td headers="liabilities current_liabilities tlst dec2020" className="text-align-right no-border">
              {formatCurrency(bsData['Term loan, short term'][colName2020])}
            </td>
          </tr>
        )}

        {bsData['Total current liabilities'] && (
          <tr>
            <th headers="liabilities current_liabilities" id="tcl" className="text-header indent">
              Total current liabilities
            </th>
            <td headers="liabilities current_liabilities tcl dec2021" className="text-align-right">
              <div className="subtotal" />
              {formatCurrency(bsData['Total current liabilities'][colName2021])}
            </td>
            <td headers="liabilities current_liabilities tcl dec2020" className="text-align-right">
              <div className="subtotal" />
              {formatCurrency(bsData['Total current liabilities'][colName2020])}
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="3" role="presentation" className="spacer" />
        </tr>

        <tr>
          <th id="longterm_liabilities" colSpan="3" scope="colgroup" headers="liabilities" className="indent">
            Long term liabilities:
          </th>
        </tr>

        {bsData['Finance lease liabilities_2'] && (
          <tr>
            <th headers="liabilities longterm_liabilities" id="fllll" className="text-header indent">
              Finance lease liabilities
            </th>
            <td headers="liabilities longterm_liabilities fllll dec2021" className="text-align-right">
              {formatCurrency(bsData['Finance lease liabilities_2'][colName2021])}
            </td>
            <td headers="liabilities longterm_liabilities fllll dec2020" className="text-align-right">
              {formatCurrency(bsData['Finance lease liabilities_2'][colName2020])}
            </td>
          </tr>
        )}

        {bsData['Operating lease liabilities_2'] && (
          <tr>
            <th headers="liabilities longterm_liabilities" id="ollll" className="text-header indent">
              Operating lease liabilities
            </th>
            <td headers="liabilities longterm_liabilities ollll dec2021" className="text-align-right">
              {formatCurrency(bsData['Operating lease liabilities_2'][colName2021])}
            </td>
            <td headers="liabilities longterm_liabilities ollll dec2020" className="text-align-right">
              {formatCurrency(bsData['Operating lease liabilities_2'][colName2020])}
            </td>
          </tr>
        )}

        {bsData['Deferred revenue_2'] && (
          <tr>
            <th headers="liabilities longterm_liabilities" id="drll" className="text-header indent">
              Deferred revenue
            </th>
            <td headers="liabilities longterm_liabilities drll dec2021" className="text-align-right">
              {formatCurrency(bsData['Deferred revenue_2'][colName2021])}
            </td>
            <td headers="liabilities longterm_liabilities drll dec2020" className="text-align-right">
              {formatCurrency(bsData['Deferred revenue_2'][colName2020])}
            </td>
          </tr>
        )}

        {bsData['Term loan, long term'] && (
          <tr>
            <th headers="liabilities longterm_liabilities" id="tllt" className="text-header indent">
              Term loan, long term
            </th>
            <td headers="liabilities longterm_liabilities tllt dec2021" className="text-align-right no-border">
              {formatCurrency(bsData['Term loan, long term'][colName2021])}
            </td>
            <td headers="liabilities longterm_liabilities tllt dec2020" className="text-align-right no-border">
              {formatCurrency(bsData['Term loan, long term'][colName2020])}
            </td>
          </tr>
        )}

        {bsData['Total liabilities'] && (
          <tr>
            <th headers="liabilities longterm_liabilities" id="tl" className="text-header indent">
              Total liabilities
            </th>
            <td headers="liabilities longterm_liabilities tl dec2021" className="text-align-right">
              <div className="subtotal">{formatCurrency(bsData['Total liabilities'][colName2021])}</div>
            </td>
            <td headers="liabilities longterm_liabilities tl dec2020" className="text-align-right">
              <div className="subtotal">{formatCurrency(bsData['Total liabilities'][colName2020])}</div>
            </td>
          </tr>
        )}

        <tr>
          <td colSpan="4" role="presentation" className="spacer" />
        </tr>

        <tr>
          <th id="stockholders_equity" scope="colgroup" headers="liabilities" className="indent">
            <div>Stockholders' equity:</div>
            <div className="text-weight-book">Preferred stock, $0.00001 par value, 10,000 shares authorized</div>
          </th>
        </tr>

        {bsData[
          'Series A Convertible Preferred stock, $0.00001 par value, 200 shares authorized, zero and 90 shares issued and outstanding as of December 31, 2021 and 2020, respectively'
        ] && (
          <tr>
            <th headers="liabilities stockholders_equity" id="seriesa" className="text-header indent w-60">
              <div>Series A Convertible Preferred Stock,</div>
              <div className="text-weight-book">
                $0.00001 par value, 200 shares authorized, zero and 90 shares issued and outstanding as of December 31,
                2021 and 2020, respectively
              </div>
            </th>
            <td headers="liabilities stockholders_equity seriesa dec2021" className="text-align-right">
              {formatCurrency(
                bsData[
                  'Series A Convertible Preferred stock, $0.00001 par value, 200 shares authorized, zero and 90 shares issued and outstanding as of December 31, 2021 and 2020, respectively'
                ][colName2021]
              )}
            </td>
            <td headers="liabilities stockholders_equity seriesa dec2020" className="text-align-right">
              {formatCurrency(
                bsData[
                  'Series A Convertible Preferred stock, $0.00001 par value, 200 shares authorized, zero and 90 shares issued and outstanding as of December 31, 2021 and 2020, respectively'
                ][colName2020]
              )}
            </td>
          </tr>
        )}

        {bsData[
          'Common stock, $0.00001 par value, 50,000 shares authorized, 11,435 and 10,130 shares issued and outstanding as of December 31, 2021 and 2020, respectively'
        ] && (
          <tr>
            <th headers="liabilities stockholders_equity" id="common" className="text-header indent w-60">
              <div>Common stock,</div>
              <div className="text-weight-book">
                $0.00001 par value, 50,000 shares authorized, 11,435 and 10,130 shares issued and outstanding as of
                December 31, 2021 and 2020, respectively
              </div>
            </th>
            <td headers="liabilities stockholders_equity common dec2021" className="text-align-right">
              {formatCurrency(
                bsData[
                  'Common stock, $0.00001 par value, 50,000 shares authorized, 11,435 and 10,130 shares issued and outstanding as of December 31, 2021 and 2020, respectively'
                ][colName2021]
              )}
            </td>
            <td headers="liabilities stockholders_equity common dec2020" className="text-align-right">
              {formatCurrency(
                bsData[
                  'Common stock, $0.00001 par value, 50,000 shares authorized, 11,435 and 10,130 shares issued and outstanding as of December 31, 2021 and 2020, respectively'
                ][colName2020]
              )}
            </td>
          </tr>
        )}

        {bsData['Additional paid-in capital'] && (
          <tr>
            <th headers="liabilities stockholders_equity" id="apc" className="text-header indent">
              Additional paid-in capital
            </th>
            <td headers="liabilities stockholders_equity apc dec2021" className="text-align-right">
              {formatCurrency(bsData['Additional paid-in capital'][colName2021])}
            </td>
            <td headers="liabilities stockholders_equity apc dec2020" className="text-align-right">
              {formatCurrency(bsData['Additional paid-in capital'][colName2020])}
            </td>
          </tr>
        )}

        {bsData['Accumulated deficit'] && (
          <tr>
            <th headers="liabilities stockholders_equity" id="ad" className="text-header indent">
              Accumulated deficit
            </th>
            <td
              headers="liabilities stockholders_equity ad dec2021"
              className="text-align-right no-border"
              aria-label="negative value"
            >
              ({formatCurrency(bsData['Accumulated deficit'][colName2021])})
            </td>
            <td
              headers="liabilities stockholders_equity ad dec2020"
              className="text-align-right no-border"
              aria-label="negative value"
            >
              ({formatCurrency(bsData['Accumulated deficit'][colName2020])})
            </td>
          </tr>
        )}

        {bsData["Total stockholders' equity"] && (
          <tr>
            <th headers="liabilities stockholders_equity" id="tse" className="text-header indent">
              Total stockholders' equity
            </th>
            <td headers="liabilities stockholders_equity tse dec2021" className="text-align-right no-border">
              <div className="subtotal">{formatCurrency(bsData["Total stockholders' equity"][colName2021])}</div>
            </td>
            <td headers="liabilities stockholders_equity tse dec2020" className="text-align-right no-border">
              <div className="subtotal">{formatCurrency(bsData["Total stockholders' equity"][colName2020])}</div>
            </td>
          </tr>
        )}

        <tr className="spacer-row-subtotal">
          <td role="presentation" />
          <td role="presentation">
            <div className="subtotal">&nbsp;</div>
          </td>
          <td role="presentation">
            <div className="subtotal">&nbsp;</div>
          </td>
        </tr>

        {bsData["Total liabilities and stockholders' equity"] && (
          <tr>
            <th headers="liabilities stockholders_equity" id="tlase" className="text-header indent">
              Total liabilities and stockholders' equity
            </th>
            <td headers="liabilities stockholders_equity tlase dec2021" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>
                  {formatCurrency(bsData["Total liabilities and stockholders' equity"][colName2021])}
                </div>
              </div>
            </td>
            <td headers="liabilities stockholders_equity tlase dec2020" className="text-align-right no-border">
              <div className="total">
                <div className="total2">
                  <span className="mr-2">$</span>
                  {formatCurrency(bsData["Total liabilities and stockholders' equity"][colName2020])}
                </div>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </StyledTable>
  )
}

const GaapTable = ({ data }) => {
  const gaapData = data

  return (
    <>
      <StyledTable
        summary="Column one has the non-GAAP earnings loss source, other columns show the amounts by date period"
        id="ae_revenue"
        className="financial-table"
        border="0"
        cellpadding="0"
        cellspacing="0"
      >
        <thead>
          <tr>
            <td className="no-border" />
            <td className="no-border" />
            <th colSpan="2" id="threemonths" scope="col" className="no-wrap no-border" style={{ textAlign: 'center' }}>
              Three months ended
              <br />
              December 31,
            </th>
            <th colSpan="2" id="year-ended" scope="col" className="no-wrap no-border" style={{ textAlign: 'center' }}>
              Year ended
              <br />
              December 31,
            </th>
          </tr>
          <tr>
            <td className="no-border" />
            <td className="no-border" />
            <th id="3m2021" scope="col" colSpan="1" className="no-wrap">
              <div className="subtotal full-width space-between" style={{ display: 'block', textAlign: 'center' }}>
                2021
              </div>
            </th>
            <th id="3m2020" scope="col" colSpan="1" className="no-wrap">
              <div className="subtotal full-width space-between" style={{ display: 'block', textAlign: 'center' }}>
                2020
              </div>
            </th>

            <th id="year2021" scope="col" colSpan="1" className="no-wrap">
              <div className="subtotal full-width space-between" style={{ display: 'block', textAlign: 'center' }}>
                2021
              </div>
            </th>
            <th id="year2020" scope="col" colSpan="1" className="no-wrap">
              <div className="subtotal full-width space-between" style={{ display: 'block', textAlign: 'center' }}>
                2020
              </div>
            </th>
          </tr>
          <tr>
            <td colSpan="6" className="text-align-left no-wrap">
              ( In thousands, except per share data )
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th id="assets" colSpan="6" scope="colgroup" className="large-header">
              Non-GAAP Earnings (Loss) Reconciliation
            </th>
          </tr>
          {gaapData['Net loss (GAAP)'] && (
            <tr>
              <th id="net-loss-gaap" className="text-header" colSpan="2">
                Net loss (GAAP)
              </th>
              <td
                headers="threemonths 3m2020 assets net-loss-gaap"
                className="text-align-right"
                aria-label="negative value"
              >
                <span className="mr-2">$</span>({formatCurrency(gaapData['Net loss (GAAP)'][threeMonth2021ColName])})
              </td>
              <td
                headers="threemonths 3m2021 assets net-loss-gaap"
                className="text-align-right"
                aria-label="negative value"
              >
                <span className="mr-2">$</span>({formatCurrency(gaapData['Net loss (GAAP)'][threeMonth2020ColName])})
              </td>
              <td
                headers="year-ended year2021 assets net-loss-gaap"
                className="text-align-right"
                aria-label="negative value"
              >
                <span className="mr-2">$</span>({formatCurrency(gaapData['Net loss (GAAP)'][colName2021])})
              </td>
              <td
                headers="year-ended year2020 assets net-loss-gaap"
                className="text-align-right"
                aria-label="negative value"
              >
                <span className="mr-2">$</span>({formatCurrency(gaapData['Net loss (GAAP)'][colName2020])})
              </td>
            </tr>
          )}

          {gaapData['Non-cash valuation adjustments to liabilities'] && (
            <tr>
              <th className="text-header" colSpan="2" id="nval">
                Non-cash valuation adjustments to liabilities
              </th>
              <td headers="threemonths 3m2020 assets nval" className="text-align-right">
                {formatCurrency(gaapData['Non-cash valuation adjustments to liabilities'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2021 assets nval" className="text-align-right">
                {formatCurrency(gaapData['Non-cash valuation adjustments to liabilities'][threeMonth2020ColName])}
              </td>
              <td headers="year-ended year2021 assets nval" className="text-align-right">
                {formatCurrency(gaapData['Non-cash valuation adjustments to liabilities'][colName2021])}
              </td>
              <td headers="year-ended year2020 assets nval" className="text-align-right" aria-label="negative value">
                ({formatCurrency(gaapData['Non-cash valuation adjustments to liabilities'][colName2020])})
              </td>
            </tr>
          )}

          {gaapData['Interest expense'] && (
            <tr>
              <th className="text-header" colSpan="2" id="ie">
                Interest expense
              </th>
              <td headers="threemonths 3m2020 assets ie" className="text-align-right">
                {formatCurrency(gaapData['Interest expense'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2021 assets ie" className="text-align-right">
                {formatCurrency(gaapData['Interest expense'][threeMonth2020ColName])}
              </td>
              <td headers="year-ended year2021 assets ie" className="text-align-right">
                {formatCurrency(gaapData['Interest expense'][colName2021])}
              </td>
              <td headers="year-ended year2020 assets ie" className="text-align-right">
                {formatCurrency(gaapData['Interest expense'][colName2020])}
              </td>
            </tr>
          )}

          {gaapData['Stock-based compensation expense'] && (
            <tr>
              <th className="text-header" colSpan="2" id="sbce">
                Stock-based compensation expense
              </th>
              <td headers="threemonths 3m2020 assets sbce" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2021 assets sbce" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense'][threeMonth2020ColName])}
              </td>
              <td headers="year-ended year2021 assets sbce" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense'][colName2021])}
              </td>
              <td headers="year-ended year2020 assets sbce" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense'][colName2020])}
              </td>
            </tr>
          )}

          {gaapData['Severance expense (1)'] && (
            <tr>
              <th className="text-header" colSpan="2" id="se">
                Severance expense (1)
              </th>
              <td headers="threemonths 3m2020 assets se" className="text-align-right">
                {formatCurrency(gaapData['Severance expense (1)'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2021 assets se" className="text-align-right">
                {formatCurrency(gaapData['Severance expense (1)'][threeMonth2020ColName])}
              </td>
              <td headers="year-ended year2021 assets se" className="text-align-right">
                {formatCurrency(gaapData['Severance expense (1)'][colName2021])}
              </td>
              <td headers="year-ended year2020 assets se" className="text-align-right">
                {formatCurrency(gaapData['Severance expense (1)'][colName2020])}
              </td>
            </tr>
          )}

          {gaapData['Litigation expense (2)'] && (
            <tr>
              <th className="text-header" colSpan="2" id="le">
                Litigation expense (2)
              </th>
              <td headers="threemonths 3m2020 assets le" className="text-align-right">
                {formatCurrency(gaapData['Litigation expense (2)'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2021 assets le" className="text-align-right">
                {formatCurrency(gaapData['Litigation expense (2)'][threeMonth2020ColName])}
              </td>
              <td headers="year-ended year2021 assets le" className="text-align-right">
                {formatCurrency(gaapData['Litigation expense (2)'][colName2021])}
              </td>
              <td headers="year-ended year2020 assets le" className="text-align-right">
                {formatCurrency(gaapData['Litigation expense (2)'][colName2020])}
              </td>
            </tr>
          )}

          {gaapData['Loss on impairment of long-lived assets'] && (
            <tr>
              <th className="text-header" colSpan="2" id="lila">
                Loss on impairment of long-lived assets
              </th>
              <td headers="threemonths 3m2020 assets lila" className="text-align-right">
                {formatCurrency(gaapData['Loss on impairment of long-lived assets'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2021 assets lila" className="text-align-right">
                {formatCurrency(gaapData['Loss on impairment of long-lived assets'][threeMonth2020ColName])}
              </td>
              <td headers="year-ended year2021 assets lila" className="text-align-right">
                {formatCurrency(gaapData['Loss on impairment of long-lived assets'][colName2021])}
              </td>
              <td headers="year-ended year2020 assets lila" className="text-align-right">
                {formatCurrency(gaapData['Loss on impairment of long-lived assets'][colName2020])}
              </td>
            </tr>
          )}

          {gaapData['Loss on disposal of property and equipment'] && (
            <tr>
              <th className="text-header" colSpan="2" id="ldpe">
                Loss on disposal of property and equipment
              </th>
              <td headers="threemonths 3m2020 assets ldpe" className="text-align-right">
                {formatCurrency(gaapData['Loss on disposal of property and equipment'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2021 assets ldpe" className="text-align-right">
                {formatCurrency(gaapData['Loss on disposal of property and equipment'][threeMonth2020ColName])}
              </td>
              <td headers="year-ended year2021 assets ldpe" className="text-align-right">
                {formatCurrency(gaapData['Loss on disposal of property and equipment'][colName2021])}
              </td>
              <td headers="year-ended year2020 assets ldpe" className="text-align-right">
                {formatCurrency(gaapData['Loss on disposal of property and equipment'][colName2020])}
              </td>
            </tr>
          )}

          {gaapData['Gain on loan forgiveness'] && (
            <tr>
              <th className="text-header" colSpan="2" id="glf">
                Gain on loan forgiveness
              </th>
              <td headers="threemonths 3m2020 assets glf" className="text-align-right no-border">
                {formatCurrency(gaapData['Gain on loan forgiveness'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2021 assets glf" className="text-align-right no-border">
                {formatCurrency(gaapData['Gain on loan forgiveness'][threeMonth2020ColName])}
              </td>
              <td
                headers="year-ended year2021 assets glf"
                className="text-align-right no-border"
                aria-label="negative value"
              >
                ({formatCurrency(gaapData['Gain on loan forgiveness'][colName2021])})
              </td>
              <td headers="year-ended year2020 assets glf" className="text-align-right no-border">
                {formatCurrency(gaapData['Gain on loan forgiveness'][colName2020])}
              </td>
            </tr>
          )}

          {gaapData['Non-GAAP loss'] && (
            <tr>
              <th className="text-header" colSpan="2" id="ngl">
                Non-GAAP loss
              </th>
              <td
                headers="threemonths 3m2020 assets ngl"
                className="text-align-right no-border"
                aria-label="negative value"
              >
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>({formatCurrency(gaapData['Non-GAAP loss'][threeMonth2021ColName])}
                      )
                    </div>
                  </div>
                </div>
              </td>
              <td
                headers="threemonths 3m2021 assets ngl"
                className="text-align-right no-border"
                aria-label="negative value"
              >
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>({formatCurrency(gaapData['Non-GAAP loss'][threeMonth2020ColName])}
                      )
                    </div>
                  </div>
                </div>
              </td>
              <td
                headers="year-ended year2021 assets ngl"
                className="text-align-right no-border"
                aria-label="negative value"
              >
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>({formatCurrency(gaapData['Non-GAAP loss'][colName2021])})
                    </div>
                  </div>
                </div>
              </td>
              <td
                headers="year-ended year2020 assets ngl"
                className="text-align-right no-border"
                aria-label="negative value"
              >
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>({formatCurrency(gaapData['Non-GAAP loss'][colName2020])})
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          )}

          <tr>
            <td colSpan="6" role="presentation" className="spacer" />
          </tr>

          <tr>
            <th id="non-gaap-earnings" colSpan="6" scope="colgroup" className="large-header">
              Non-GAAP Earnings (Loss) per Diluted Share Reconciliation
            </th>
          </tr>

          {gaapData['Net loss per common share (GAAP) — diluted'] && (
            <tr>
              <th className="text-header" colSpan="2" id="nlcs">
                Net loss per common share (GAAP) — diluted
              </th>
              <td
                headers="threemonths 3m2021 non-gaap-earnings nlcs"
                className="text-align-right"
                aria-label="negative value"
              >
                <div>
                  <span className="mr-2">$</span>(
                  {formatCurrency(gaapData['Net loss per common share (GAAP) — diluted'][threeMonth2021ColName])})
                </div>
              </td>
              <td
                headers="threemonths 3m2020 non-gaap-earnings nlcs"
                className="text-align-right"
                aria-label="negative value"
              >
                <div>
                  <span className="mr-2">$</span>(
                  {formatCurrency(gaapData['Net loss per common share (GAAP) — diluted'][threeMonth2020ColName])})
                </div>
              </td>
              <td
                headers="year-ended year2021 non-gaap-earnings nlcs"
                className="text-align-right"
                aria-label="negative value"
              >
                <div>
                  <span className="mr-2">$</span>(
                  {formatCurrency(gaapData['Net loss per common share (GAAP) — diluted'][colName2021])})
                </div>
              </td>
              <td
                headers="year-ended year2020 non-gaap-earnings nlcs"
                className="text-align-right"
                aria-label="negative value"
              >
                <div>
                  <span className="mr-2">$</span>(
                  {formatCurrency(gaapData['Net loss per common share (GAAP) — diluted'][colName2020])})
                </div>
              </td>
            </tr>
          )}

          {gaapData['Non-cash valuation adjustments to liabilities - 2'] && (
            <tr>
              <th className="text-header" colSpan="2" id="nval">
                Non-cash valuation adjustments to liabilities
              </th>
              <td headers="threemonths 3m2021 non-gaap-earnings nval" className="text-align-right">
                {formatCurrency(gaapData['Non-cash valuation adjustments to liabilities - 2'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2020 non-gaap-earnings nval" className="text-align-right">
                {formatCurrency(gaapData['Non-cash valuation adjustments to liabilities - 2'][threeMonth2020ColName])}
              </td>
              <td headers="year-ended year2021 non-gaap-earnings nval" className="text-align-right">
                {formatCurrency(gaapData['Non-cash valuation adjustments to liabilities - 2'][colName2021])}
              </td>
              <td
                headers="year-ended year2020 non-gaap-earnings nval"
                className="text-align-right"
                aria-label="negative value"
              >
                ({formatCurrency(gaapData['Non-cash valuation adjustments to liabilities - 2'][colName2020])})
              </td>
            </tr>
          )}

          {gaapData['Interest expense - 2'] && (
            <tr>
              <th className="text-header" colSpan="2" id="ie">
                Interest expense
              </th>
              <td headers="threemonths 3m2021 non-gaap-earnings ie" className="text-align-right">
                {formatCurrency(gaapData['Interest expense - 2'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2020 non-gaap-earnings ie" className="text-align-right">
                {formatCurrency(gaapData['Interest expense - 2'][threeMonth2020ColName])}
              </td>
              <td headers="year-ended year2021 non-gaap-earnings ie" className="text-align-right">
                {formatCurrency(gaapData['Interest expense - 2'][colName2021])}
              </td>
              <td headers="year-ended year2020 non-gaap-earnings ie" className="text-align-right">
                {formatCurrency(gaapData['Interest expense - 2'][colName2020])}
              </td>
            </tr>
          )}

          {gaapData['Stock-based compensation expense - 2'] && (
            <tr>
              <th className="text-header" colSpan="2" id="sbce">
                Stock-based compensation expense
              </th>
              <td headers="threemonths 3m2021 non-gaap-earnings sbce" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense - 2'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2020 non-gaap-earnings sbce" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense - 2'][threeMonth2020ColName])}
              </td>
              <td headers="year-ended year2021 non-gaap-earnings sbce" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense - 2'][colName2021])}
              </td>
              <td headers="year-ended year2020 non-gaap-earnings sbce" className="text-align-right">
                {formatCurrency(gaapData['Stock-based compensation expense - 2'][colName2020])}
              </td>
            </tr>
          )}

          {gaapData['Severance expense (1) - 2'] && (
            <tr>
              <th className="text-header" colSpan="2" id="se-2">
                Severance expense (1)
              </th>
              <td headers="threemonths 3m2021 non-gaap-earnings se-2" className="text-align-right">
                <div>{formatCurrency(gaapData['Severance expense (1) - 2'][threeMonth2021ColName])}</div>
              </td>
              <td headers="threemonths 3m2020 non-gaap-earnings se-2" className="text-align-right">
                <div>{formatCurrency(gaapData['Severance expense (1) - 2'][threeMonth2020ColName])}</div>
              </td>
              <td headers="year-ended year2021 non-gaap-earnings se-2" className="text-align-right">
                <div>{formatCurrency(gaapData['Severance expense (1) - 2'][colName2021])}</div>
              </td>
              <td headers="year-ended year2020 non-gaap-earnings se-2" className="text-align-right">
                <div>{formatCurrency(gaapData['Severance expense (1) - 2'][colName2020])}</div>
              </td>
            </tr>
          )}

          {gaapData['Litigation expense (2) - 2'] && (
            <tr>
              <th className="text-header" colSpan="2" id="le-2">
                Litigation expense (2)
              </th>
              <td headers="threemonths 3m2021 non-gaap-earnings le-2" className="text-align-right">
                <div>{formatCurrency(gaapData['Litigation expense (2) - 2'][threeMonth2021ColName])}</div>
              </td>
              <td headers="threemonths 3m2020 non-gaap-earnings le-2" className="text-align-right">
                <div>{formatCurrency(gaapData['Litigation expense (2) - 2'][threeMonth2020ColName])}</div>
              </td>
              <td headers="year-ended year2021 non-gaap-earnings le-2" className="text-align-right">
                <div>{formatCurrency(gaapData['Litigation expense (2) - 2'][colName2021])}</div>
              </td>
              <td headers="year-ended year2020 non-gaap-earnings le-2" className="text-align-right">
                <div>{formatCurrency(gaapData['Litigation expense (2) - 2'][colName2020])}</div>
              </td>
            </tr>
          )}

          {gaapData['Loss on impairment of long-lived assets - 2'] && (
            <tr>
              <th className="text-header" colSpan="2" id="lila-2">
                Loss on impairment of long-lived assets
              </th>
              <td headers="threemonths 3m2021 non-gaap-earnings lila-2" className="text-align-right">
                {formatCurrency(gaapData['Loss on impairment of long-lived assets - 2'][threeMonth2021ColName])}
              </td>
              <td headers="threemonths 3m2020 non-gaap-earnings lila-2" className="text-align-right">
                {formatCurrency(gaapData['Loss on impairment of long-lived assets - 2'][threeMonth2020ColName])}
              </td>
              <td headers="year-ended year2021 non-gaap-earnings lila-2" className="text-align-right">
                {formatCurrency(gaapData['Loss on impairment of long-lived assets - 2'][colName2021])}
              </td>
              <td headers="year-ended year2020 non-gaap-earnings lila-2" className="text-align-right">
                {formatCurrency(gaapData['Loss on impairment of long-lived assets - 2'][colName2020])}
              </td>
            </tr>
          )}

          {gaapData['Loss on disposal of property and equipment - 2'] && (
            <tr>
              <th className="text-header" colSpan="2" id="ldpe-2">
                Loss on disposal of property and equipment
              </th>
              <td headers="threemonths 3m2021 non-gaap-earnings ldpe-2" className="text-align-right">
                <div>
                  {formatCurrency(gaapData['Loss on disposal of property and equipment - 2'][threeMonth2021ColName])}
                </div>
              </td>
              <td headers="threemonths 3m2020 non-gaap-earnings ldpe-2" className="text-align-right">
                <div>
                  {formatCurrency(gaapData['Loss on disposal of property and equipment - 2'][threeMonth2020ColName])}
                </div>
              </td>
              <td headers="year-ended year2021 non-gaap-earnings ldpe-2" className="text-align-right">
                <div>{formatCurrency(gaapData['Loss on disposal of property and equipment - 2'][colName2021])}</div>
              </td>
              <td headers="year-ended year2020 non-gaap-earnings ldpe-2" className="text-align-right">
                <div>{formatCurrency(gaapData['Loss on disposal of property and equipment - 2'][colName2020])}</div>
              </td>
            </tr>
          )}

          {gaapData['Gain on loan forgiveness - 2'] && (
            <tr>
              <th className="text-header" colSpan="2" id="glf-2">
                Gain on loan forgiveness
              </th>
              <td headers="threemonths 3m2021 non-gaap-earnings glf-2" className="text-align-right no-border">
                <div>{formatCurrency(gaapData['Gain on loan forgiveness - 2'][threeMonth2021ColName])}</div>
              </td>
              <td headers="threemonths 3m2020 non-gaap-earnings glf-2" className="text-align-right no-border">
                <div>{formatCurrency(gaapData['Gain on loan forgiveness - 2'][threeMonth2020ColName])}</div>
              </td>
              <td
                headers="year-ended year2021 non-gaap-earnings glf-2"
                className="text-align-right no-border"
                aria-label="negative value"
              >
                <div>({formatCurrency(gaapData['Gain on loan forgiveness - 2'][colName2021])})</div>
              </td>
              <td headers="year-ended year2020 non-gaap-earnings glf-2" className="text-align-right no-border">
                <div>{formatCurrency(gaapData['Gain on loan forgiveness - 2'][colName2020])}</div>
              </td>
            </tr>
          )}

          {gaapData['Non-GAAP loss per diluted share (3)'] && (
            <tr>
              <th className="text-header" colSpan="2" style={{ paddingRight: '0px', fontSize: '95%' }} id="nglds">
                Non-GAAP loss per diluted share (3)
              </th>
              <td
                headers="threemonths 3m2021 non-gaap-earnings nglds"
                className="text-align-right no-border"
                aria-label="negative value"
              >
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>(
                      {formatCurrency(gaapData['Non-GAAP loss per diluted share (3)'][threeMonth2021ColName])})
                    </div>
                  </div>
                </div>
              </td>
              <td
                headers="threemonths 3m2020 non-gaap-earnings nglds"
                className="text-align-right no-border"
                aria-label="negative value"
              >
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>(
                      {formatCurrency(gaapData['Non-GAAP loss per diluted share (3)'][threeMonth2020ColName])})
                    </div>
                  </div>
                </div>
              </td>
              <td
                headers="year-ended year2021 non-gaap-earnings nglds"
                className="text-align-right no-border"
                aria-label="negative value"
              >
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>(
                      {formatCurrency(gaapData['Non-GAAP loss per diluted share (3)'][colName2021])})
                    </div>
                  </div>
                </div>
              </td>
              <td
                headers="year-ended year2020 non-gaap-earnings nglds"
                className="text-align-right no-border"
                aria-label="negative value"
              >
                <div className="total">
                  <div className="total2">
                    <div className="subtotal">
                      <span className="mr-2">$</span>(
                      {formatCurrency(gaapData['Non-GAAP loss per diluted share (3)'][colName2020])})
                    </div>
                  </div>
                </div>
              </td>
            </tr>
          )}

          <tr>
            <td role="presentation" className="spacer no-border" />
          </tr>

          {gaapData['Diluted weighted average shares (4)'] && (
            <tr>
              <th className="text-header" colSpan="2" id="dwas">
                Diluted weighted average shares (4)
              </th>
              <td
                headers="threemonths 3m2021 non-gaap-earnings dwas"
                className="text-align-right no-border"
                style={{ verticalAlign: 'bottom' }}
              >
                <div className="total">
                  {formatCurrency(gaapData['Diluted weighted average shares (4)'][threeMonth2021ColName])}
                </div>
              </td>
              <td
                headers="threemonths 3m2020 non-gaap-earnings dwas"
                className="text-align-right no-border"
                style={{ verticalAlign: 'bottom' }}
              >
                <div className="total">
                  {formatCurrency(gaapData['Diluted weighted average shares (4)'][threeMonth2020ColName])}
                </div>
              </td>
              <td
                headers="year-ended year2021 non-gaap-earnings dwas"
                className="text-align-right no-border"
                style={{ verticalAlign: 'bottom' }}
              >
                <div className="total">
                  {formatCurrency(gaapData['Diluted weighted average shares (4)'][colName2021])}
                </div>
              </td>
              <td
                headers="year-ended year2020 non-gaap-earnings dwas"
                className="text-align-right no-border"
                style={{ verticalAlign: 'bottom' }}
              >
                <div className="total">
                  {formatCurrency(gaapData['Diluted weighted average shares (4)'][colName2020])}
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </StyledTable>
      <ol style={{ marginTop: '24px', marginLeft: '0px', listStyleType: 'none' }}>
        <li>
          (1) Represents severance expense associated with the move of our technology center to Portland, Oregon, and is
          exclusive of accrued vacation paid upon termination of employment.
        </li>
        <li>(2) Represents legal expenses towards intellectual property litigation pursued by the Company.</li>
        <li>
          (3) Non-GAAP earnings per adjusted diluted share for our common stock is computed using the more dilutive of
          the two-class method or the if-converted method.
        </li>
        <li>
          (4) The number of diluted weighted average shares used for this calculation is the same as the weighted
          average common shares outstanding share count when the Company reports a GAAP and non-GAAP net loss.
        </li>
      </ol>
    </>
  )
}

const StyledEarningsReport = styled.div`
  margin: 100px 0 0;
`

const checkExpiredData = timestamp => {
  const dayMs = 1000 * 60 * 60 * 24
  const dayAgo = Date.now() - dayMs
  return timestamp > dayAgo
}

const EarningsReport = () => {
  const [bsData, setBsData] = useState({})
  const [isData, setIsData] = useState({})
  const [gaapData, setGaapData] = useState({})

  const fetchTableData = tableName =>
    new Promise(resolve => {
      const data = {}
      // if no / expired data exists then query AirTable
      AIRTABLE_BASE(tableName)
        .select({
          maxRecords: 1000,
          pageSize: 100,
          view: 'Grid view',
        })
        .eachPage(
          (records, fetchNextPage) => {
            // This function (`page`) will get called for each page of records.
            records.forEach(record => {
              let label = record.fields[labelColName]
              if (data[label]) {
                console.warn('Multiple earnings results with same name: ', label)
                label += '_2'
              }

              if (tableName === 'BS') {
                data[label] = {
                  [colName2021]: record.fields[colName2021],
                  [colName2020]: record.fields[colName2020],
                }
              } else if (tableName === 'IS') {
                data[label] = {
                  [colName2021]: record.fields[colName2021],
                  [colName2020]: record.fields[colName2020],
                }
              } else if (tableName === 'Non-GAAP') {
                data[label] = {
                  [threeMonth2021ColName]: record.fields[threeMonth2021ColName],
                  [threeMonth2020ColName]: record.fields[threeMonth2020ColName],
                  [colName2021]: record.fields[colName2021],
                  [colName2020]: record.fields[colName2020],
                }
              }
            })

            // To fetch the next page of records, call `fetchNextPage`.
            // If there are more records, `page` will get called again.
            // If there are no more records, `done` will get called.
            fetchNextPage()
          },
          err => {
            // on finish
            if (err) {
              console.error(err)
              resolve({})
            } else {
              resolve(data)
            }
          }
        )
    })

  useEffect(async () => {
    // check local storage and timestamp for existing data
    const ls = getPersistedData()
    const lsEarningsData = ls['earnings-data-q4-2021']

    if (lsEarningsData && checkExpiredData(lsEarningsData.timestamp)) {
      // exists and is not expired
      setBsData(lsEarningsData.bsData)
      setIsData(lsEarningsData.isData)
      setGaapData(lsEarningsData.gaapData)
    } else {
      // Request AirTable base data
      const bsRes = await fetchTableData('BS')
      const isRes = await fetchTableData('IS')
      const gaapRes = await fetchTableData('Non-GAAP')
      // set data timestamp
      persistData(
        Object.assign(ls, {
          'earnings-data-q4-2021': {
            timestamp: Date.now(),
            bsData: bsRes,
            isData: isRes,
            gaapData: gaapRes,
          },
        })
      )
      setBsData(bsRes)
      setIsData(isRes)
      setGaapData(gaapRes)
    }
  }, [])

  return (
    <StyledEarningsReport>
      <Text center heading3 aria-level="2" role="heading">
        AUDIOEYE, INC.
        <br />
        STATEMENTS OF OPERATIONS
      </Text>
      <Space height={60} />
      <IsTable data={isData} />
      <Space height={100} />
      <Text center heading3 aria-level="2" role="heading">
        AUDIOEYE, INC.
        <br />
        BALANCE SHEETS
      </Text>
      <Space height={60} />
      <BsTable data={bsData} />
      <Space height={100} />
      <Text center heading3 aria-level="2" role="heading">
        AUDIOEYE, INC.
        <br />
        RECONCILIATIONS OF GAAP to NON-GAAP FINANCIAL MEASURES
      </Text>
      <Space height={60} />
      <GaapTable data={gaapData} />
    </StyledEarningsReport>
  )
}

export default EarningsReport
